import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import EmbedPlayer from '../../components/EmbedPlayer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Follow these steps to create a responsive embed`}</h3>
    <ul>
      <li parentName="ul">{`Insert the player embed code into an HTML page`}</li>
      <li parentName="ul">{`Set the iframe tag’s `}<inlineCode parentName="li">{`width`}</inlineCode>{` and `}<inlineCode parentName="li">{`height`}</inlineCode>{` attributes to `}<inlineCode parentName="li">{`100%`}</inlineCode>{` inside of the embed code`}</li>
      <li parentName="ul">{`Change iframe tag’s CSS position parameter to `}<inlineCode parentName="li">{`absolute`}</inlineCode>{` and set the left and top css parameters to `}<inlineCode parentName="li">{`0`}</inlineCode>{` if needed`}</li>
      <li parentName="ul">{`Add a div container around the iframe tag with the following css parameters where `}<inlineCode parentName="li">{`padding-bottom`}</inlineCode>{` parameter’s value is calculated from the original proportions of the embedded content (eg. 9 / 16 = 0.5625)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div
  id="Container"
  style="padding-bottom:56.25%; position:relative; display:block; width: 100%"
>
  <iframe
    id="UstreamIframe"
    src="https://video.ibm.com/embed/recorded/131906526"
    width="100%" height="100%"
    style="position:absolute; top:0; left: 0"
    allowfullscreen
    webkitallowfullscreen
    frameborder="0"
    referrerpolicy="no-referrer-when-downgrade"
  >
  </iframe>
</div>
`}</code></pre>
    <h3>{`Example`}</h3>

    <EmbedPlayer src="https://video.ibm.com/embed/recorded/131906526" mdxType="EmbedPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      